import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./Registration.css";
import ImageLogo from "../Assets/Assets/AK_society_logo.png";
import axios from 'axios';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const Registration = () => {
  const [emailOrPhone, setEmailOrPhone] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [emailOrPhoneError, setEmailOrPhoneError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [isnotification,setisNotification] =useState('');
  const [confirmpasswordError, setconfirmPasswordError] = useState("");
  const location = useLocation();
  const param = location?.search || null;

console.log('param===========',param)
  const { email } = getQueryParams(param);

  
  function getQueryParams(url) {
    console.log('url----',url)
    // const queryString = url != null & url.split('?')[1]; // Extract the query string part
    const params = new URLSearchParams(url);
    const email = params.get('email');
    return { email };
  }
  useEffect(() => {
    if (isValidEmail(email)) {
      setEmailOrPhone(email);
      setisNotification('')
    }else{
      setisNotification('Note: Please Try to Create password through the Sent Email link... !')
    }
  }, [email]);
  console.log('email----------',email)
  console.log('email0rphonr----------',emailOrPhone)

  const handleSubmit = async (e) => {
    e.preventDefault();
    setEmailOrPhoneError("");
    setPasswordError("");
    setconfirmPasswordError("");

    if (!validateInputs()) return;

    setLoading(true);

    const data = {
      newPassword: password,
      confirmPassword: confirmPassword,
      roleId: 2
    };

    if (isValidEmail(emailOrPhone)) {
      data.email = emailOrPhone;
    } else {
      data.phone = emailOrPhone;
    }

    try {
      const response = await axios.post('https://societybackend.helenzysinc.com/api/createPassword', data);

      handleApiResponse(response);

    } catch (error) {
      console.log("createPassword API Error", error)
    } finally {
      setLoading(false);
    }
  };

  const validateInputs = () => {
    let isValid = true;

    if (!emailOrPhone.trim()) {
      setEmailOrPhoneError("Please enter your email or phone number");
      isValid = false;
    } else if (!isValidEmail(emailOrPhone) && !isValidPhone(emailOrPhone)) {
      setEmailOrPhoneError("Please enter a valid email address or phone number");
      isValid = false;
    }

    if (!password.trim()) {
      setPasswordError("Please enter your password");
      isValid = false;
    }


    if (!confirmPassword.trim()) {
      setconfirmPasswordError("Please enter your Confirm password");
      isValid = false;
    }

    if (password !== confirmPassword) {
      setconfirmPasswordError("Passwords do not match");
      isValid = false;
    }

    // if (!isValidPassword(password)) {
    //   NotificationManager.error("Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one special character");
    //   isValid = false;
    // }

    return isValid;
  };

  const isValidEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const isValidPhone = (phone) => {
    const re = /^\d{10}$/;
    return re.test(phone);
  };


  const togglePasswordVisibility = (field) => {
    if (field === 'password') {
      setShowPassword(!showPassword);
    } else {
      setShowConfirmPassword(!showConfirmPassword);
    }
  };

  const handleApiResponse = (response) => {
    if (response?.data?.success) {
      NotificationManager.success(<div className="notification-custom">
        <span className="notification-icon">✔️</span> {/* Icon */}
        <span className="notification-message">{response.data.message}</span> {/* Message */}
      </div>, '', 3000);
      window.location.href = '/login'
    } else {
      NotificationManager.error(<div className="notification-custom">
        <span className="notification-icon">❌</span> {/* Icon */}
        <span className="notification-message">{response.data.message}</span> {/* Message */}
      </div>, '', 3000);
    }
  };
  console.log('isnotification====',isnotification)

  return (
    <div className="register-container">
      <form className="register-form" onSubmit={handleSubmit}>
        <img
          src={ImageLogo}
          width={"25%"}
          height={"25%"}
          style={{ margin: "0 auto" }}
          alt="Logo"
        />
       {isnotification && <div className="notifymgs"><p className="py-4" style={{color:'#761b18',fontWeight:'600'}}>{isnotification}</p></div>}
        <h2>Create Password</h2>
        <div className="form-group">
          <input
            type="text"
            value={emailOrPhone}
            onChange={(e) => setEmailOrPhone(e.target.value)}
            placeholder="Enter your email or phone number"
            readOnly
          />
          {emailOrPhoneError && <div className="error-message">{emailOrPhoneError}</div>}
        </div>
        <div className="form-group password-input-container">
          <div className="password-input-wrapper">
            <input
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter New Password"
            />
            <button
              type="button"
              onClick={() => togglePasswordVisibility('password')}
              className="password-toggle-btn-register"
            >
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </button>
          </div>
          {passwordError && <div className="error-message">{passwordError}</div>}
        </div>
        <div className="form-group password-input-container">
          <input
            type={showConfirmPassword ? "text" : "password"}
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            placeholder="Confirm Password"
          />
          <button
            type="button"
            onClick={() => togglePasswordVisibility('confirmPassword')}
            className="password-toggle-btn-register"
          >
            {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
          </button>
          {confirmpasswordError && <div className="error-message">{confirmpasswordError}</div>}
        </div>
        <button type="submit" className="create-btn" disabled={loading}>
          {loading ? "Creating..." : "Create"}
        </button>
        <div className="register-link">
          Already have an Account? <Link to="/login">LOGIN</Link>
        </div>
      </form>
      <NotificationContainer />
    </div>
  );
};

export default Registration;