import React from 'react';
import './Header.css';
import ImageLogo from "../Assets/Assets/AK_society_logo.png";
import UsersLogo from "../Assets/Assets/profile.png";
import PowerOptions from "../Assets/Assets/poweroff.png";

const Header = () => {
  const handleNavigate = (pagename) => {
    if (pagename === '/login') {
      localStorage.removeItem('userId');
    }
    window.location.href = pagename;
  }

  return (
    <header className="header">
      <div className="logohead">
        <img
          className="logo-img"
          src={ImageLogo}
          alt="Logo"
          onClick={() => handleNavigate('/home')}
        />
      </div>
      <div className='mytitlle'>Society</div>
      <div className="header-icons">
        <button
          className="icon-button user-icon"
          onClick={() => handleNavigate('/myaccount')}
        >
          <img
            className="icon-img"
            src={UsersLogo}
            alt="User Icon"
          />
        </button>
        <button
          className="icon-button power-icon"
          onClick={() => handleNavigate('/login')}
        >
          <img
            className="icon-img"
            src={PowerOptions}
            alt="Power Icon"
          />
        </button>
      </div>
    </header>
  );
};

export default Header;
