import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./ForgotPassword.css";
import ImageLogo from "../Assets/Assets/AK_society_logo.png";
import axios from 'axios';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';

const ForgotPassword = () => {
  const [emailOrPhone, setEmailOrPhone] = useState("");
  const [loading, setLoading] = useState(false);
  const [emailOrPhoneError, setEmailOrPhoneError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setEmailOrPhoneError("");

    if (!validateInputs()) return;

    setLoading(true);

    const data = {
      roleId: 2
    };

    if (isValidEmail(emailOrPhone)) {
      data.email = emailOrPhone;
    } else {
      data.phone = emailOrPhone;
    }

    try {
      const response = await axios.post('https://societybackend.helenzysinc.com/api/memberForgotpassword', data);
      
      handleApiResponse(response);
    } catch (error) {
      console.log("Fogot API Error", error)
    } finally {
      setLoading(false);
    }
  };

  const validateInputs = () => {
    let isValid = true;

    if (!emailOrPhone.trim()) {
      setEmailOrPhoneError("Please enter your email id");
      isValid = false;
    } else if (!isValidEmail(emailOrPhone) && !isValidPhone(emailOrPhone)) {
      setEmailOrPhoneError("Please enter a valid email id");
      isValid = false;
    }

    return isValid;
  };

  const isValidEmailOrPhone = (value) => {
    return isValidEmail(value) || isValidPhone(value);
  };

  const isValidEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const isValidPhone = (phone) => {
    const re = /^\d{10}$/;
    return re.test(phone);
  };

  const handleApiResponse = (response) => {
    if (response?.data?.success) {
      NotificationManager.success(<div className="notification-custom">
        <span className="notification-icon">✔️</span> {/* Icon */}
        <span className="notification-message">{response.data.message}</span> {/* Message */}
      </div>, '', 3000);
      window.location.href="/resetpassword"
    } else {
      NotificationManager.error(  <div className="notification-custom">
        <span className="notification-icon">❌</span> {/* Icon */}
        <span className="notification-message">{response.data.message}</span> {/* Message */}
      </div>, '', 3000);
    }
  };

  return (
    <div className="forgot-container">
      <form className="forgot-form" onSubmit={handleSubmit}>

        <img
          src={ImageLogo}
          width={"25%"}
          height={"25%"}
          style={{ margin: "0 auto" }}
          alt="Logo"
        />
        
        <h2>Forgot Password</h2>
        <div className="form-group">
          <input
            type="text"
            value={emailOrPhone}
            onChange={(e) => setEmailOrPhone(e.target.value)}
            placeholder="Enter your Email ID"
          />
          {emailOrPhoneError && <div className="error-message">{emailOrPhoneError}</div>}
        </div>
        <button type="submit" className="forgot-btn" style={{marginTop: '31px'}} disabled={loading}>
          {loading ? "Submitting..." : "Submit"}
        </button>
        <div className="register-link">
          Remember your password? <Link to="/login">LOGIN</Link>
        </div>
      </form>
      <NotificationContainer />
    </div>
  );
};

export default ForgotPassword;