import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import InitialPage from "./Pages/InitialPage";
import Registration from "./Pages/Registration";
import Login from "./Pages/Login";
import ForgotPassword from "./Pages/ForgotPassword";
import Home from "./Pages/Home";
import ResetPassword from "./Pages/ResetPassword";
import MyAccount from "./Pages/MyAccount";
import 'bootstrap/dist/css/bootstrap.min.css';
import PrivateRoute from "./Pages/PrivateRoute";

function App() {
  return (
    <Router>  
      <div className="App">
        <Routes>
          <Route path="/" element={<InitialPage />} />
          <Route path="/register" element={<Registration />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/resetpassword" element={<ResetPassword />} />
          
          {/* Private Routes */}
          <Route element={<PrivateRoute />}>
            <Route path="/home" element={<Home />} />
            <Route path="/myaccount" element={<MyAccount />} />
          </Route>
        </Routes>
      </div>
    </Router>
  );
}

export default App;