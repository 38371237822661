import React, { useEffect } from "react";
import "./IntialPage.css";

const InitialPage = () => {
   useEffect(() => {
    setTimeout(() => {
      window.location.href = "/login";
    }, 2000);
  }, []);
  return (
    <div className="splash-screen">
      <div className="logo"></div>
    </div>
  );
};

export default InitialPage;