import React, { useState, useEffect } from 'react';
import Header from './Header';
import './MyAccount.css';
import UsersLogoProfile from "../Assets/Assets/user.png.png";
import { Alert } from 'react-bootstrap';
import axios from 'axios';
import { Link } from 'react-router-dom';

const MyAccount = () => {
  const userId = localStorage.getItem('userId');

  const [loading, setLoading] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: ''
  });

  const [alertMessage, setAlertMessage] = useState('');
  const [alertVariant, setAlertVariant] = useState('');
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    const getMemberdata = async () => {
      setLoading(true)
      try {
        const response = await axios.post('https://societybackend.helenzysinc.com/api/getSocietyMemberAccount', {
          memberId: userId,         
        });
        console.log('response',response.data.data)
        if (response?.data?.success == true) {
          setSubmitted(true);
          setFormData(response?.data?.data);
          setAlertMessage(response?.data?.message);
          setAlertVariant('success');
          setLoading(false);
        } else {
          setSubmitted(true);
          setAlertMessage(response?.data?.message);
          setAlertVariant('danger');
          setLoading(false);
        }
      } catch (error) {
        setAlertMessage(error);
        setAlertVariant('danger');
        setLoading(false);
      }
    }

    getMemberdata();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setAlertMessage("")
      setAlertVariant("");
      setSubmitted(false);
    }, 2000);
  }, [alertMessage]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    // Simulating API call for updating user data
    setTimeout(() => {
      setEditMode(false);
      setLoading(false);
    }, 1000);
  };

  if (loading) {
    return (
      <div className="my-account">
        <Header />
        <div className="loader">Loading...</div>
      </div>
    );
  }

  return (
    <div className="scrollable-content">
    <div className="my-account">
      <Header />
      <div className="account-container">
        <div className="profile-icon">
          <img src={UsersLogoProfile} alt="User Profile" />
        </div>
        {/* {submitted && alertMessage && (
          <Alert variant={alertVariant} className="alert-message">
            {alertMessage}
          </Alert>
        )} */}
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              readOnly={!editMode}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="phone">Mobile Number</label>
            <input
              type="tel"
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleInputChange}
              readOnly={!editMode}
              required
              pattern="\+91 [0-9]{10}"
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email ID</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              readOnly={!editMode}
              required
            />
          </div>
          <p className='Note'> Note: Please contact Admin for changes</p>     
         
       {/* {editMode ? (
            <button type="submit" className="save-button">Save</button>
          ) : (
            <button type="button" onClick={() => setEditMode(true)} className="edit-button">Edit</button>
          )} */}
        </form>
        <div className='btn-container'> 
          <Link to='/Home'>
          <button className='Back' >  Back</button>
          </Link>
         </div>
      </div>
    </div>
    </div>
  );
};

export default MyAccount;