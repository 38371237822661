import React, { useState, useEffect } from 'react';
import { Card, Accordion, Button, Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Home.css';
import Header from './Header';
import axios from 'axios';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';




const Home = () => {
  const userId = localStorage.getItem('userId');

  const [activePolls, setActivePolls] = useState({});
  const [loading, setLoading] = useState(false);
  const [submittedPolls, setSubmittedPolls] = useState(new Set());
  const [pollResults, setPollResults] = useState(null);
  const [loadingPolls, setLoadingPolls] = useState({});
  const colors = ['#FF7F00', '#1E90FF', '#3CB371', 'rgba(245, 40, 145, 0.8)'];

  useEffect(() => {
    fetchPolls();
  }, []);


  const fetchPolls = async () => {
    try {
      setLoading(true);
      const pollResultsResponse = await axios.post('https://societybackend.helenzysinc.com/api/getallpollList', {
        memberId: userId,
      });
      handleApiResponse(pollResultsResponse);
    } catch (error) {
      console.log("getallpollList API Error", error)
      setLoading(false);
    }
  };


  const handleApiResponse = (response) => {
    if (response?.data?.success) {
      setPollResults(response?.data?.data);
      setLoading(false);
    } else {
      setLoading(false);
      NotificationManager.error(response.data.message || 'Home geting error', '', 3000, { className: 'notification-error' });
    }
  };

  const handleVote = (pollId, option) => {
    setActivePolls(prev => ({ ...prev, [pollId]: option }));
  };

  const handleCancel = (pollId) => {
    setActivePolls(prev => {
      const newState = { ...prev };
      delete newState[pollId];
      return newState;
    });
  };

  const handleSubmitVote = async (questionId) => {
    setLoadingPolls(prev => ({ ...prev, [questionId]: true }));
    try {
      const response = await axios.post('https://societybackend.helenzysinc.com/api/memberVoting', {
        memberId: userId,
        votingQuestionId: questionId,
        votedOption: activePolls[questionId],
        createdBy: userId
      });
      if (response?.data?.success == true) {
        console.log('response.data.message', response.data.message);
        await NotificationManager.success(<div className="notification-custom">
          <span className="notification-icon">✔️</span> {/* Icon */}
          <span className="notification-message">{response.data.message}</span> {/* Message */}
        </div>, '', 3000);
        fetchPolls();
        setLoadingPolls(false);
      } else {
        NotificationManager.error(<div className="notification-custom">
          <span className="notification-icon">❌</span> {/* Icon */}
          <span className="notification-message">{response.data.message}</span> {/* Message */}
        </div>, '', 3000);
        setLoadingPolls(false);
      }
    } catch (error) {
      console.log("memberVoting API Error", error)
      setLoadingPolls(false);
    }
  };
  console.log('pollResults', pollResults)


  const allQuestions = pollResults && Array.isArray(pollResults) ? pollResults.reduce((acc, group) => {
    return acc.concat(group.questions);
  }, [])
    : [];

  // console.log('allQuestions',allQuestions);



  return (
    <div className="scrollable-content">
      <div className="poll-page">
        <Header />
        <main style={{ margin: '5%' }}>
          <section className="poll-list">
            {pollResults && pollResults.map(group => {
              const filteredQuestions = (group.questions || []).filter(question => question.memberVoted == null);
              if (filteredQuestions.length > 0) {
                return (
                  <React.Fragment key={group.groupName}>
                    <h5 className='px-4 py-2' style={{ textAlign: 'start' }}>{group.groupName}</h5>
                    {filteredQuestions.map((question) => (
                      <Card key={question.id} className="poll-item">
                        <Card.Body>
                          <Card.Title className='radionames'>{question.Question}</Card.Title>
                          <div className="options">
                            {(question.options || []).map((option, optionIndex) => (
                              <div key={optionIndex} style={{ display: 'flex' }}>
                                <input
                                  className='radionames'
                                  type="radio"
                                  name={`poll-option-${question.id}`}
                                  value={option}
                                  checked={activePolls[question.id] === option}
                                  onChange={() => handleVote(question.id, option)}
                                />
                                <span className='radionames' style={{ marginLeft: '10px' }}>{option}</span>
                              </div>
                            ))}
                          </div>
                          <div className="buttons" style={{ display: 'flex', justifyContent: 'end' }}>
                            <button
                              type='button'
                              style={{ backgroundColor: 'transparent', color: 'black', border: 'none' }}
                              onClick={() => handleCancel(question.id)}
                            >
                              Cancel
                            </button>
                            <button
                              type='button'
                              style={{ backgroundColor: '#FF6700', color: '#FFFFFF', borderRadius: '4px', padding: '6px 30px 6px 30px', border: 'none' }}
                              onClick={() => handleSubmitVote(question.id)}
                              disabled={!activePolls[question.id] || loadingPolls[question.id]}
                            >
                              {loadingPolls[question.id] ? 'Sending...' : 'Send'}
                            </button>
                          </div>
                        </Card.Body>
                      </Card>
                    ))}
                  </React.Fragment>
                );
              }
              return null;
            })}
          </section>

          <section className="all-polls">
            <h5 style={{ color: '#181616', fontWeight: 'bolder' }}>All Polls</h5>
            {pollResults && pollResults.map((group, groupIndex) => (
              <div key={groupIndex} className="poll-group p-3 rounded" style={{ backgroundColor: '#f8f9fa' }}>
                <h5 className="group-name mb-3">{group.groupName}</h5>
                {group.questions && group.questions.map((poll, pollIndex) => (
                  <Accordion key={pollIndex} className="mb-3">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header className="bg-light">{poll.Question}</Accordion.Header>
                      <Accordion.Body>
                        <div className="options-container">
                          {poll.optionsHistory && poll.optionsHistory.options && poll.optionsHistory.options.map((option, optionIndex) => {
                            const totalVotes = poll.optionsHistory.totalVotes || 0;
                            const percentage = totalVotes > 0 ? (option.count / totalVotes) * 100 : 0;
                            // console.log('option___________',option.option);
                            // console.log('poll*************',poll.memberVoted);
                            const isChecked = (option.option?.trim() ?? '') === (poll.memberVoted?.trim() ?? '');
                            console.log('isChecked===============', isChecked)
                            console.log('poll===============', poll);
                            console.log('option===============', option);
                            console.log('{option.option.count}', option.count)
                            return (
                              <div
                                key={optionIndex}
                                className="option-row mb-2 p-2 rounded d-flex justify-content-between  align-items-center"
                                style={{
                                  backgroundColor: colors[optionIndex % colors.length],
                                }}
                              >
                                <div className="option-content d-flex align-items-center">
                                  <input type="radio" className="option-circle me-2" checked={isChecked} readOnly />
                                  <span className="option-text">{option.option}</span>
                                  {/* {poll?.optionsHistory?.options && poll.optionsHistory.options.map((item) => item.count)} */}
                                </div>
                                <div className="option-percentage" style={{
                                  width: `${percentage}%`,
                                  minWidth: '40px',
                                  padding: '4px 8px',
                                  // backgroundColor: 'rgba(0,123,255,0.2)',
                                  opacity: 20,
                                  // border:'1px solid',
                                  borderRadius: '4px',
                                  textAlign: 'right'
                                }}>
                                  <span className="percentage-text" style={{ fontSize: '12px' }}>{percentage.toFixed(1)}%</span>
                                  {/* {poll?.optionsHistory?.options && poll.optionsHistory.options.map((item) => item.count)} */}

                                </div>
                                <div style={{ position: 'absolute', right: '-60px', fontFamily: 'serif' }}>
                                  {option?.count > 1 ? <span style={{position: 'relative', left: '8px'}}>{option.count + " votes"}</span> : option.count + " vote"}
                                </div>
                              </div>
                            );

                          })}
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                ))}
              </div>
            ))}

          </section>
        </main>
        <NotificationContainer />
        {loading && <div className="loader">Loading...</div>}
      </div>
    </div>
  );
};

export default Home;
