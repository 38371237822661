import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./ResetPassword.css";
import ImageLogo from "../Assets/Assets/AK_society_logo.png";
import axios from 'axios';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const ResetPassword = () => {
  const [emailOrPhone, setEmailOrPhone] = useState("");
  const [genPassword, setGenPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [emailOrPhoneError, setEmailOrPhoneError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [genpasswordError, setgenPasswordError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    setEmailOrPhoneError("");
    setPasswordError("");
    setgenPasswordError("");

    if (!validateInputs()) return;

    setLoading(true);

    const data = {
      roleId: 2,
      genPassword: genPassword,
      newPassword: newPassword
    };
    if (isValidEmail(emailOrPhone)) {
      data.email = emailOrPhone;
    } else {
      data.phone = emailOrPhone;
    }

    try {
      const response = await axios.post('https://societybackend.helenzysinc.com/api/memberResetpassword', data);
      handleApiResponse(response);

    } catch (error) {
      console.log("memberResetpassword API Error", error)
    } finally {
      setLoading(false);
    }
  };

  const validateInputs = () => {
    let isValid = true;

    if (!emailOrPhone.trim()) {
      setEmailOrPhoneError("Please enter your email id");
      isValid = false;
    } else if (!isValidEmail(emailOrPhone) && !isValidPhone(emailOrPhone)) {
      setEmailOrPhoneError("Please enter a valid email id");
      isValid = false;
    }

    if (!newPassword.trim()) {
      setPasswordError("Please enter your New password");
      isValid = false;
    }


    if (!genPassword.trim()) {
      setgenPasswordError("Please enter your Generated Code");
      isValid = false;
    }


    // if (!isValidPassword(password)) {
    //   NotificationManager.error("Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one special character");
    //   isValid = false;
    // }

    return isValid;
  }; 

  const isValidEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const isValidPhone = (phone) => {
    const re = /^\d{10}$/;
    return re.test(phone);
  };

  const handleApiResponse = (response) => {
    if (response?.data?.success) {
      NotificationManager.success(<div className="notification-custom">
        <span className="notification-icon">✔️</span> {/* Icon */}
        <span className="notification-message">{response.data.message}</span> {/* Message */}
      </div>, '', 3000);
      window.location.href="/login"
    } else {
      NotificationManager.error(<div className="notification-custom">
        <span className="notification-icon">❌</span> {/* Icon */}
        <span className="notification-message">{response.data.message}</span> {/* Message */}
      </div>, '', 3000);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="reset-container">
      <form className="reset-form" onSubmit={handleSubmit}>
        <img
          src={ImageLogo}
          width={"25%"}
          height={"25%"}
          style={{ margin: "0 auto" }}
          alt="Logo"
        />
        
        <h2>Reset Password</h2>
        <div className="form-group">
          <input
            type="text"
            value={emailOrPhone}
            onChange={(e) => setEmailOrPhone(e.target.value)}
            placeholder="Enter your Email ID"
            disabled={loading}
          />
          {emailOrPhoneError && <div className="error-message">{emailOrPhoneError}</div>}
        </div>
        <div className="form-group">
          <input
            type="text"
            value={genPassword}
            onChange={(e) => setGenPassword(e.target.value)}
            placeholder="Generated Code Password"
          />
          {genpasswordError && <div className="error-message">{genpasswordError}</div>}
        </div>
        <div className="form-group password-input-container">
  <div className="input-with-icon">
    <input
      type={showPassword ? "text" : "password"}
      value={newPassword}
      onChange={(e) => setNewPassword(e.target.value)}
      placeholder="Enter New Password"
    />
    <button 
      type="button" 
      onClick={togglePasswordVisibility} 
      className="password-toggle-btn-rest"
    >
      {showPassword ? <FaEyeSlash /> : <FaEye />}
    </button>
  </div>
  {passwordError && <div className="error-message">{passwordError}</div>}
</div>
        <button type="submit" className="reset-btn" disabled={loading}>
          {loading ? "Submitting..." : "Submit"}
        </button>
        <div className="register-link">
          Remember your password? <Link to="/login">LOGIN</Link>
        </div>
      </form>
      <NotificationContainer />
    </div>
  );
};

export default ResetPassword;